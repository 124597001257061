<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <v-hover
      v-slot="{ hover }"
      open-delay="100">
      <v-card :elevation="hover ? 12 : 2" class="mx-auto">
        <img v-bind:src="utils.imageWait(value.picture_base64)" width="100%"
          :height="[$vuetify.breakpoint.mdAndUp ? '150vh' : '100%']"
          @click.stop="viewArticle(value)">
        <v-card-title><span class="Cdiv">{{value.articles_name}}</span></v-card-title>
        <!-- <v-card-text class="pb-0 mb-0" v-html="value.articles_detail.substring(0, value.articles_detail.indexOf('</p>')).substring(0, 40)"></v-card-text> -->
        <v-divider></v-divider>
        <v-card-actions class="pt-1 mt-1">
          <span class="caption">{{ $t('MeeTeeMeeNgern1.view') }} {{value.view_count}} คน</span>
          <v-spacer></v-spacer>
          <v-chip
            class="text-uppercase ma-0"
            color="pink"
            dark
            label
            small
            @click.stop="viewArticle(value)"
          >
            <span class="body-1">{{ $t('MeeTeeMeeNgern1.readMore') }}</span>
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
      }
    },
    methods: {
      viewArticle (value) {
        this.$router.push({ name: 'Article', params: { propValue: value } })
      },
    }
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
.Cdiv{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  font-weight: normal;    
}
</style>
